.transaction-table {
  margin-bottom: 30px;
}

.transaction__row {
  margin-bottom: 30px;
  margin-top: 10px;

  p {
    font-weight: bold;
  }
}