.form-list {
  &__row {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 10px 0;

    .btn {
      margin-top: 19px;
      margin-bottom: 0;
    }
  }

  &__row:first-child {
    border-top: 1px solid rgba(224, 224, 224, 1);
  }

  &__btn {
    margin-top: 10px;
  }
}
