.media-card__info {
  padding-top: 20px;

  span {
    display: block;
    margin-bottom: 10px;
  }
}

.media-card__title {
  font-weight: 700;
  margin-bottom: 10px;
}

.media-card__tabs {
  margin-top: 20px;
  min-height: 200px;
}
