.multi-range-slider {
  padding-bottom: 10px;
  margin-top: 5px;

  input[type=range] {
    cursor: pointer;
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: 8px;
    width: 100%;
    padding: 0;
    opacity: 0;
    user-select: none;
  }

  input[type=range]::-webkit-slider-thumb {
    pointer-events: all;
    width: 16px;
    height: 16px;
    border-radius: 0;
    border: 0 none;
    -webkit-appearance: none;
  }

  &__slider {
    position: relative;
    z-index: 1;
    height: 8px;
    margin: 0 10px;
  }

  &__track {
    user-select: none;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
    }
  }

  &__range {
    user-select: none;
    position: absolute;
    z-index: 2;
    left: 25%;
    right: 25%;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #e56b2c;
  }

  &__thumb {
    position: absolute;
    z-index: 3;
    width: 16px;
    height: 16px;
    background-color: #e56b2c;
    border-radius: 50%;

    &--left {
      transform: translate(-8px, -4px);
    }

    &--right {
      transform: translate(8px, -4px);
    }
  }

  &__range-wrap {
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--text {
      margin: 0;
    }
  }
}
