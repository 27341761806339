.video-play-icon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    @include themify($themes) {
      color: themed('colorText');
    }
  }
  svg{
    @include themify($themes) {
      color: themed('colorText');
    }
  }
}