.multiple-search-select {
  @include themify($themes) {
    border: 1px solid themed('colorFieldsBorder');
  }
  padding-top: 5px;

  &__selection {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__label {
    background-color: #e7e2e2;
    color: #646777;
    border-radius: 5px;
    padding: 0 12px;
    cursor: pointer;
    border: #d0c6c6 solid 1px;
    margin-left: 5px;
    margin-bottom: 5px;

    &:hover {
      background-color: #d0c6c6;
    }
  }

  &__input {
    border: none !important;
    width: auto !important;
    height: 22px !important;
    margin-bottom: 5px;
    flex-grow: 1;
  }

  &__dropdown {
    list-style-type: none;
    margin: 0;
    padding: 0;
    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
    }
    position: absolute;
    width: 100%;
    z-index: 100;
    background-color: #fff;
  }

  &__dropdown-item {
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      background-color: #e7e2e2;
    }
  }

  &--disabled {
    background: #f2f4f7;
  }
}
