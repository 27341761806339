.from-to-input {
  display: flex;
  align-items: center;

  &__dash-sign {
    width: 15px;
    height: 1px;
    background-color: #646777;
    margin: 0 5px;
  }
}
