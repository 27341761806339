#content-selector{
  max-width: none;
  width:70%;
  color: #646777;
  .btn{
    margin: 0.3rem 0.4rem;
  }
  .modal-title{
    font-size: 2em;
  }
}
