.content-manager {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  .add-content-widget,
  .loader{
    margin: 10px 25px;
  }
  &__header{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .filter{
    margin: 0;
    .form__form-group-field{
      display: flex;
      align-items: center;
    }
  }
}

.add-content-widget{
  @include themify($themes) {
    color: themed('colorText');
  }
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 8px;
  border: 1px solid $color-light-gray;
  width: 120px;
  height: 50px;
  .separator{
    height: 80%;
    width: 1px;
    background-color: $color-light-gray;
  }
  a{
    color: unset;
    height: min-content;
  }
  svg{
    &:hover{
      color: $color-accent-hover;
    }
  }
}
