.link-confirmation {
  min-height: calc(100vh - 185px);
  text-align: center;
}

.link-confirmation__icon {
  margin: 0 auto;
  padding-top: 75px;
  position: relative;
  width: 120px;
}

.link-confirmation__link {
  fill: $color-gray;
  opacity: 0.16;
  width: 120px;
  height: 120px;
}

.link-confirmation__title {
  margin-bottom: 15px;
  color: $color-gray;
}

.link-confirmation__sub {
  color: $color-gray;
  font-size: 18px;
  line-height: 24px;
}

@media screen and (min-width: 576px) {

  .link-confirmation__icon {
    padding-top: 155px;
  }
}
