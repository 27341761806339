.filter {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  background-color: #fff;
  margin-bottom: 30px;
  width: 100%;
  padding: 15px 30px;
  border-radius: 5px;

  input, textarea, select {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;

    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
      color: themed('colorText');
    }

    &[disabled] {

      @include themify($themes) {
        background: themed('colorBackgroundBody');
      }

      &:focus, &:active {

        @include themify($themes) {
          border-color: themed('colorBorder');
        }
      }
    }

    &:focus, &:active {
      outline: none;
      border-color: $color-accent;
    }
  }

  button {
    margin-bottom: 0;
  }
}
