.gallery {
  width: 100%;

  &__current-img {
    height: 360px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 10px;
  
    @include themify($themes) {
      border: 1px solid themed('colorBorder');
    }
  
    img {
      height: 100%;
      width: auto;
    }
  }

  &__no_photo {
    height: 450px;
    display: flex;
  
    img {
      width: 200px;
      height: 200px;
      margin: auto;
    }
  }

  &__gallery {
    display: flex;
  }

  &__img-preview {
    width: 80px;
    height: 80px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
    padding: 0;
    background: transparent;
  
    @include themify($themes) {
      border: 1px solid themed('colorBorder');
    }
  
    &:last-child {
      margin-right: 0;
    }
  
    img {
      height: 80px;
    }
  }

  @media screen and (max-width: 1199px) {
    margin: auto;
  }
}

.react-images__view {

  &.react-images__view--isModal {

    img {
      width: unset;
    }
  }
}
