.dropdown {

  &:last-child .btn {
    margin-right: 0;
  }

  .btn.icon svg {
    height: 18px;
    width: 20px;
    margin-right: -5px;
  }

  .btn-group {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    .btn {

      &:last-child {
        border-left: none;
        padding: 7px;

        svg {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

.dropdown__menu {
  width: 170px;
  border-radius: 0;
  border: none;
  margin: 0;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  font-size: 13px;

  @include themify($themes) {
    background: themed('colorBackground');
  }

  button {
    transition: all 0.3s;
    padding: 7px 24px;
    cursor: pointer;

    @include themify($themes) {
      color: themed('colorText');
    }

    &:hover {

      @include themify($themes) {
        background: themed('colorHover');
        color: themed('colorText');
      }
    }
  }

  button:focus, button:active, button:active:focus {
    outline: none;
  }

  .active button {
    background-color: $color-blue;

    &:hover {
      background-color: $color-blue-hover;
    }
  }
}
