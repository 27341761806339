.card {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;
}

.card-body {
  padding: 30px;
  height: 100%;
  border-radius: 5px;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.card__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.card__newline {
  white-space: pre-wrap;
}
