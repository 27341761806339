.file-list {
  color: #646777;

  &__icon {
    fill: #646777;
    height: 30px;
    width: 30px;
  }

  &__row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(224, 224, 224, 1);

    &:first-child {
      border-top: 1px solid rgba(224, 224, 224, 1);
    }

    &:hover {
      background-color: #f2f4f7;
    }
  }

  &__cell {
    padding: 10px 16px;
  }

  &__link {
    color: inherit;

    &:hover {
      color: $color-accent;
    }
  }
}
