@mixin center {
    display:flex;
    justify-content: center;
    align-items: center;
}

@mixin overflow-control {
    &__title{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__icon-bar{
        white-space: nowrap;
    }
}

@mixin tabulated-edit-form {
  &__menu-link{
    @include themify($themes) {
      color: themed('colorText');
    }
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    &--create:hover{
      color: $color-accent-hover;
    }
  }
  &__form{
    margin-top: 20px;
  }
  &__edit-container{
    margin-top: 10px;
    .MuiButtonBase-root.MuiIconButton-root{
      padding: 3px;
      margin-left: 10px;
    }
    .nav-link{
      cursor: default;
      user-select: none;
    }
  }
}

@mixin tabulated-display {
  &__nav-container{
    margin-top: 20px;
    .nav-link{
      @include themify($themes) {
        color: themed('colorText');
      }
      cursor: default;
      user-select: none;
    }
  }
  &__menu-field{
    margin: 20px;
    p{
      margin-bottom: 5px;
    }
  }
}

.menu-top-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu-org-container {
    width: 90%;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.menu-card {
    @include themify($themes) {
        background-color: themed('colorBackground');
        color: themed('colorText');
    }
    font-size: 1.2em;
    width: 100%;
    min-height: 200px;
    box-shadow: 0 0 10px -5px $color-gray;
    border-radius: 8px;
    &__header {
        @include themify($themes) {
            background-color: themed('colorBackgroundBody');
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 10px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    &__body {
        padding: 15px;
        display: grid;
        gap: 15px;
        grid-template-columns: 100%;
    }
    &__button{
        @include themify($themes){
            color: themed('colorText');
        }
        margin: 0 5px;
        &:hover{
            color: $color-accent-hover;
        }
    }
    @include overflow-control;
}

.add-menu-card{
    width: 100%;
    min-height: 200px;
    border: 2px solid $color-light-gray;
    border-radius: 8px;
    &__button{
        @include themify($themes){
            color: themed('colorText');
        }
        margin: 0 5px;
        &:hover{
            color: $color-accent-hover;
        }
    }
    &__body{
        width: 100%;
        height: 100%;
        @include center;
    }
}

.menu-item{
    display: flex;
    height: 40px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 5px 2px 5px 10px;
    @include themify($themes) {
        background-color: themed('colorBackground');
        border: 2px solid themed('colorBorder');
    }
    @include overflow-control;
}

.add-menu-item{
    @include center;
    flex-wrap: nowrap;
    height: 40px;
    border-radius: 5px;
    border: 1px solid $color-light-gray;
    &__button{
        @include center;
        @include themify($themes){
            color: themed('colorText');
        }
        &:hover{
            color: $color-accent-hover;
        }
    }
}

#drop-hover{
    border: 2px solid $color-accent;
}

.content-selector{
  display: flex;
  justify-content: center;
  a {
    @include themify($themes){
        color: themed('colorText');
    }
    &:hover{
      color: $color-accent;
    }
  }
}

.menu-item-edit-page,
.menu-edit-page{
  @include tabulated-edit-form;
}

.menu-item-page,
.menu-page{
  @include tabulated-display;
}
