.dropzone {
  width: 100%;
  display: flex;
  position: relative;
  cursor: pointer;

  @include themify($themes) {
    border: 1px solid themed('colorFieldsBorder');
  }

  &.dropzone--multiple {
    min-height: 400px;

    .dropzone__input {
      min-height: 400px;
    }
  }

  &__icon {
    width: 100%;
    height: 100%;
    fill: $color-accent;
  }
}

.dropzone__input {
  width: 100%;
  height: 100%;
  min-height: 298px;
  display: flex;
  position: absolute !important;
  cursor: pointer;
}

.dropzone__drop-here {
  margin: auto;
  color: $color-additional;
}

.dropzone__imgs-wrapper {
  padding: 30px 20px;
  width: calc(100% + 30px);
  display: flex;
  flex-wrap: wrap;
}

.dropzone__img {
  margin-bottom: 30px;
  width: calc(25% - 30px);
  height: 150px;
  overflow: hidden;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include themify($themes) {
    outline: 1px solid themed('colorBorder');
  }

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    cursor: default;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  &:hover {

    &:before {
      background-color: rgba(25, 25, 25, 0.6);
    }

    .dropzone__img-delete {
      opacity: 1;
    }

    .dropzone__img-name {
      opacity: 0.7;
      color: white;
    }
  }
}

.dropzone__img-name {
  position: absolute;
  font-size: 12px;
  text-align: center;
  opacity: 0;
  transition: all 0.3s;
  z-index: 10;
  width: 100%;
  line-height: 12px;
  margin: 0;
  top: calc(50% - 6px);
}

.dropzone__img-delete {
  transition: all 0.3s;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  background: transparent;
  opacity: 0;
  color: white;
  border: 1px solid white;
  padding: 2px 7px;
}

.list-group {
  font-size: 10px;
  max-height: 120px;
  overflow-y: auto;
}

.list-group-item {
  padding: 0.25rem 0.75rem;
}
