.modal-dialog {
  max-width: 600px;
}

.modal-content {

  .modal__header {
    color: white;
    background-color: $color-accent;

    .close {
      color: white;
    }
  }

  .modal__body {

    input {
      border: 1px solid #c7cbcb;
      color: #646777;
    }

    .form__form-group-icon {
      background: #c7cbcb;
      border: 1px solid #c7cbcb;
      color: #646777;
    }

    .react-datepicker__close-icon {
      &:after {
        display: none;
      }
    }
  }
}
