$green: rgb(190, 255, 166);
$blue: rgb(166, 255, 252);
$yellow: rgb(248, 255, 166);
$red: rgb(255, 166, 166);

.severity-ok {
   background-color: $green;
    padding: 10px;

    &__card{
        text-align: center;
        background-color: $green;
        padding: 5px;
    }
}

.severity-information {
    background-color: $blue;
    padding: 10px;

    &__card{
        text-align: center;
        background-color: $blue;
        padding: 5px;
    }
}

.severity-warning {
    background-color: $yellow;
    padding: 10px;

    &__card{
        text-align: center;
        background-color: $yellow;
        padding: 5px;
    }
}

.severity-error {
    background-color: $red;
    padding: 10px;

    &__card{
        text-align: center;
        background-color: $red;
        padding: 5px;
    }
}

.status-info-modal {
    max-width: 850px;
}
