.account {
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }
}

.account__wrapper {
  margin: auto;
  padding: 10px;
}

.account__card {

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  background-color: #ffffff;
  padding: 50px 60px;
  max-width: 520px;
  width: 100%;
}

.account__btns {
  display: flex;
  width: calc(100% + 10px);
  margin: 20px 0 -20px -10px;

  a, button {
    margin: 10px 0 20px 10px;
    white-space: nowrap;
  }
}

.account__btn {
  width: 100%;
  margin-right: 0;
}

.account__forgot-password {
  position: absolute;
  font-size: 11px;
  line-height: 15px;
  bottom: -18px;
  right: 0;

  a {
    color: $color-accent;

    &:hover {
      color: $color-accent-hover;
      text-decoration: none;
    }
  }
}

.form__form-group--forgot {
  margin-bottom: 40px;
}

.account__head {
  margin-bottom: 30px;
}

.account__logo {

  img {
    display: flex;
    margin: 0 auto;
    width: auto;
    max-height: 100%;
  }
}

.account__have-account {
  text-align: center;
  margin-top: 20px;

  a {
    color: $color-accent;
    transition: all 0.3s;

    &:hover {
      color: $color-accent-hover;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 520px) {

  .account__card {
    padding: 35px 30px;
  }
}

@media screen and (max-width: 425px) {

  .account__btns {
    flex-wrap: wrap;
  }
}

.account__main {
  height: 100%;
  padding: 0;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  .account__info {
    margin-top: 5px;
  }
}

.account__dependency {
  margin-top: 0;
  margin-bottom: 0px;
  line-height: 18px;

  .badge {
    text-transform: uppercase;
    font-size: 9px;
    padding: 6px 10px;
    display: inline-table;
    height: 20px;
    color: #ffffff;
    margin-left: 5px;

    &:first-of-type {
      margin-left: 0;
    }

    &.badge-true {
      background-color: $color-green-hover;
    }

    &.badge-false {
      background-color: $color-red-hover;
    }

    @media screen and (max-width: 520px) {
      margin-top: 5px;

      &:last-of-type {
        margin-left: 0;
      }
    }
  }
}
