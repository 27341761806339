.autocomplete {
  &__dropdown {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid #e7e2e2;
    position: absolute;
    width: 100%;
    z-index: 100;
    background-color: #fff;
  }

  &__dropdown-item {
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      background-color: #e7e2e2;
    }
  }
}
