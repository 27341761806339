.form {
  display: flex;
  flex-wrap: wrap;

  input, textarea, select {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;

    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
      color: themed('colorText');
    }

    &[disabled] {

      @include themify($themes) {
        background: themed('colorBackgroundBody');
      }

      &:focus, &:active {

        @include themify($themes) {
          border-color: themed('colorBorder');
        }
      }
    }

    &:focus, &:active {
      outline: none;
      border-color: $color-accent;
    }
  }

  a {
    color: #e56b2c;
  }

  textarea {
    min-height: 85px;
  }
}

.form__full {
  width: 100%;
  height: 100%;

  &:first-child {
    margin-right: 30px;
  }

  .form__button-toolbar {
    float: right;
  }

  @media screen and (max-width: 1200px) {
    margin-right: 0;

    &:first-child {
      margin-right: 0;
    }
  }
}

.form__half {
  width: calc(50% - 15px);
  height: 100%;
  float: left;

  &:first-child {
    margin-right: 30px;
  }

  .form__button-toolbar {
    float: right;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0;

    &:first-child {
      margin-right: 0;
    }
  }
}

.form__form-group {
  margin-bottom: 20px;
  width: 100%;
  position: relative;

  &.required {

    .form__form-group-label {

      &:after {
        content:"*";
        color: $color-accent;
        padding-left: 2px;
      }
    }
  }
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
    .spinner{
      color: $color-gray;
      margin-left: 10px;
      &--requesting{
        color:$color-accent-hover;
      }
    }
}

.form__form-group-label {
  margin-bottom: 4px;
  display: inline-block;

  @include themify($themes) {
    color: themed('colorText');
  }

  span {
    color: #dddddd;
  }
}

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s;

  @include themify($themes) {
    background: themed('colorFieldsBorder');
    border: 1px solid themed('colorFieldsBorder');
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  &.active {
    background: $color-accent;
    border: 1px solid $color-accent;

    svg {
      fill: #ffffff;
    }
  }
}

.form__form-group-icon {
  padding: 6px;
  height: 32px;
  color: $color-additional;

  @include themify($themes) {
    background: themed('colorFieldsBorder');
    border: 1px solid themed('colorFieldsBorder');
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
}

.form__button-toolbar {
  margin-top: 10px;

  a {
    color: #fff;
  }
}

.form__form-group-input-wrap {
  width: 100%;
}

.form__form-group-datepicker {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  &.react-datepicker-ignore-onclickoutside {
    border-right-color: $color-additional;
  }
}

.form__form-group-error {
  font-size: 10px;
  line-height: 13px;
  color: #ad4444;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}

.form__form-group-input-wrap--error-above {

  .form__form-group-error {
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    padding: 5px 10px;
    background: #ffbcbc;
    border-radius: 3px;

    &:after {
      content: '';
      position: absolute;
      right: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid #ffbcbc;
    }
  }
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form__phone-number-prefix {
  margin-top: 5px;
  margin-right: 5px;
}

.form__subtitle {
  margin-bottom: 5px;
}
