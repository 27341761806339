.form-box {
  background-color: #F2F4F7;
  border: 1px solid #dddddd;
  padding: 0 10px 10px 10px;
  margin-top: 30px;

  &__title {
    color: #646777;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.6;
    margin-bottom: 0;
    margin-top: -25px;
  }
}
