.content-edit-page{
  &__content-link{
    @include themify($themes) {
      color: themed('colorText');
    }
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    &--create:hover{
      color: $color-accent-hover;
    }
  }
  &__form{
    margin-top: 20px;
  }
  &__edit-container{
    margin-top: 10px;
  }
  .MuiButtonBase-root.MuiIconButton-root{
    padding: 3px;
    margin-left: 10px;
  }
}

.content-page {
  &__nav-container{
    margin-top: 20px;
    .nav-link{
      @include themify($themes) {
        color: themed('colorText');
      }
      cursor: default;
      user-select: none;
    }
  }
  &__content-container{
    display: flex;
    justify-content: space-between;
  }
  &__content-field{
    margin: 20px;
    p{
      margin-bottom: 5px;
    }
  }
  &__content-html{
    margin: 20px;
    height: 400px;
    height: fit-content;
  }
  &__file-container{
    margin: 20px;
    &>p{
      margin-bottom: 5px;
      font-size: 1.2em;
    }
  }
  &__video-container{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .react-player{
      flex: 1 0 400px;
      margin: 10px;
      border-radius: 10px;
      border: 1px solid;
      border-color: #e7e2e2;
      &:hover{
        border-color: $color-accent-hover;
        svg{
          color: $color-accent-hover;
        }
      }
    }
  }
}

.toggle-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid;
  background-color: white;
  border-color: #e7e2e2;
  transition: all 0.4s;
  margin-bottom: 15px;
  @include themify($themes) {
    color: themed('colorText');
  }
  &:hover{
    border-color: darken(#e7e2e2, 10%);
  }
  svg{
    margin-left: 7px;
    @include themify($themes) {
      color: themed('colorText');
    }
  }
}