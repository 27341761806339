@import '~react-datepicker/dist/react-datepicker.css';

.date-picker {
  width: 100%;

  & > div, .react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__time-list {
    padding: 0;
  }

  .react-datepicker__time {
    overflow: hidden;
  }

  &.date-picker--interval {
    display: flex;

    & > div, .react-datepicker-wrapper, .react-datepicker__input-container {
      max-width: 170px;
    }
  }

  .date-picker__svg {
    width: 14px;
    height: 14px;
    margin: auto 10px;
  }

  .react-datepicker__header {
    background-color: $color-accent;
    border-color: $color-accent;
    border-radius: 0;
  }

  .react-datepicker__current-month, .react-datepicker-time__header,
  .react-datepicker__day-name, .react-datepicker__time-name,
  .react-datepicker__month-read-view, .react-datepicker__year-read-view {
    color: #ffffff;
  }

  .react-datepicker__month-read-view, .react-datepicker__year-read-view {
    .react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
      border-top-color: #ffffff;

      &:hover {
        border-top-color: #ffffff;
      }
    }
  }

  .react-datepicker__navigation--next {
    border-left-color: #ffffff;

    &:hover {
      border-left-color: #ffffff;
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ffffff;

    &:hover {
      border-right-color: #ffffff;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: $color-accent;
    transition: all 0.3s;

    &:hover {
      background-color: $color-accent-hover;
    }
  }

  .react-datepicker-popper {
    z-index: 100 !important;
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    border-bottom-color: $color-accent;
    margin-top: -7px;
  }

  .react-datepicker {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border: none;
    display: flex;
  }

  .react-datepicker__time-container {
    border-left: 1px solid $color-accent;
  }

  .react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__time-list-item--selected {
    background-color: $color-accent-hover !important;
  }

  .react-datepicker__close-icon::after {
    background-color: $color-accent-hover;
  }
}
