.topbar {
  width: 100%;
  position: fixed;
  top: 0;
  height: 60px;
  z-index: 101;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);

  @include themify($themes) {
    background: themed('colorBackground');
  }
}

.topbar__wrapper {
  position: relative;
  display: flex;
  height: 60px;
}

.topbar__button {
  width: 60px;
  height: 60px;
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:focus {
    outline: none;
  }

  &:hover {

    @include themify($themes) {
      background: themed('colorHover');
    }
  }

  &.topbar__button--desktop {
    display: none;
  }
}

.topbar__button-icon {
  margin: auto;
  transition: all 0.3s;
  width: 16px;
}

.topbar__logo {
  width: 150px;
  height: 32px;
  margin: auto 0;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: left;
  background-size: contain;
  display: none;

  img {
    width: auto;
    max-height: 100%;
  }

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.topbar__right {
  position: absolute;
  right: 0;
  display: flex;
  height: 100%;
  margin-right: 15px;
}

.topbar__left {
  position: absolute;
  left: 0;
  display: flex;
  height: 100%;
  width: 50%;
}

.topbar__avatar {
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 15px;
  background-color: transparent;

  &:hover, &:focus, &:active, &:focus:active {
    @include themify($themes) {
      background-color: themed('colorHover');
    }
  }

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }
}

.topbar__avatar-img, .topbar__avatar-name, .topbar__icon {
  margin: auto 0;
}

.topbar__avatar-img {
  border-radius: 50%;
  height: 36px;
  width: 36px;
}

.topbar__avatar-name {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  display: none;
  margin-left: 10px;

  @include themify($themes) {
    color: themed('colorText');
  }
}

.topbar__avatar-role {
  margin: 0;
}

.topbar__avatar-group {
  margin-top: 10px;
  margin-left: 5px;
}

.topbar__icon {
  margin-left: 8px;
  height: 18px;
  margin-top: auto;
  fill: #b1c3c8;
}

.topbar__menu {
  width: 200px;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;

  @include themify($themes) {
    background: themed('colorBackground');
  }

  button {
    padding: 0;

    &:hover {
      @include themify($themes) {
        background-color: themed('colorHover');
      }
    }
  }

  *:focus {
    outline: none;
  }
}

.topbar__menu-wrap {
  z-index: 101;
  position: absolute;
  width: 100%;
  padding: 0 10px;
  min-width: 220px;
  right: 0;
}

.topbar__link {
  display: flex;
  padding: 9px 20px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;

  @include themify($themes) {
    color: themed('colorText');
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: $color-accent;
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover {
    @include themify($themes) {
      background-color: themed('colorHover');
    }

    &:before {
      opacity: 1;
    }
  }

  &.topbar__link-active {

    &:before {
      opacity: 1;
    }
  }
}

.topbar__link-title {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
}

.topbar__link-icon {
  margin-right: 10px;
  max-height: 16px;
  max-width: 16px;

  @include themify($themes) {
    color: themed('colorIcon');
  }
}

.topbar__menu-divider {
  margin: 15px 0;

  @include themify($themes) {
    border-top: 1px solid themed('colorBorder');
  }
}

.topbar__profile {
  margin-bottom: 0;
  margin-left: 20px;
  position: relative;
}

.topbar__back {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
}

.topbar__nav {
  width: 100%;
  display: none;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.topbar .topbar__nav-dropdown-toggle {
  height: 60px;
  background: transparent;
  border-radius: 0;
  border: none;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  @include themify($themes) {
    color: themed('colorText');
  }

  &:before {
    display: none;
  }

  &:hover, &:focus, &:active, &:focus:active {

    @include themify($themes) {
      background-color: themed('colorHover');
    }
  }

  svg {
    fill: $color-additional;
    margin-left: 3px;
    height: 16px;
    width: 16px;
    margin-right: 0;
  }
}

.topbar__nav-dropdown-menu {
  width: 240px;
  border-top: 2px solid $color-accent;

  button {
    padding: 0;
  }
}

.topbar__link-badge {
  width: 26px;
  height: 14px;
  background-color: $color-red;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  margin-left: 5px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;

  span {
    position: absolute;
    left: 0;
    top: 3px;
    width: 26px;
    text-align: center;
  }
}

.topbar__nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 10px 25px;
  transition: 0.3s;
  font-size: 14px;

  @include themify($themes) {
    color: themed('colorText');
  }

  &:hover {

    @include themify($themes) {
      background-color: themed('colorHover');
    }
  }
}

.topbar__avatar-user-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  @include themify($themes) {
    background-color: themed('colorText');
  }
  color: #fff;
}

@media screen and (min-width: 480px) {

  .topbar__avatar-name {
    display: block;
  }

  .topbar__menu {
    width: 100%;
    left: 0 !important;
  }
}

@media screen and (min-width: 576px) {

  .topbar__button {

    &.topbar__button--mobile {
      display: none;
    }
  }

  .topbar.topbar--navigation {

    .topbar__button.topbar__button--mobile {
      display: block;
    }
  }
}

@media screen and (min-width: 992px) {

  .topbar__nav {
    display: flex;
  }

  .topbar.topbar--navigation {

    .topbar__logo {
      margin-left: 15px;
      display: block;
    }

    .topbar__button.topbar__button--mobile {
      display: none;
    }

    .topbar__avatar-name {
      display: none;
    }

    .topbar__profile {
      margin-left: 0;
    }
  }
}

@media screen and (min-width: 1200px) {

  .topbar.topbar--navigation {

    .topbar__avatar-name {
      display: block;
    }
  }
}

@media screen and (min-width: 1580px) {

  .topbar__nav-dropdown-toggle, .topbar__nav-link {
    width: 240px;
  }
}
