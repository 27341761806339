.default-pages {
  min-height: calc(100vh - 185px);
  text-align: center;
}

.default-pages__icon {
  margin: 0 auto;
  padding-top: 75px;
  position: relative;
  width: 120px;

  p {
    color: $color-gray;
    font-size: 60px;
    line-height: 66px;
  }
}

.default-pages__svg {
  fill: $color-gray;
  opacity: 0.16;
  width: 120px;
  height: 120px;

  &.danger {
    fill: $color-red;
    opacity: unset;
  }
}

.default-pages__title {
  margin-bottom: 15px;
  color: $color-gray;

  &.danger {
    color: $color-red;
  }
}

.default-pages__sub {
  color: $color-gray;
  font-size: 18px;
  line-height: 24px;
}

@media screen and (min-width: 576px) {

  .default-pages__icon {
    padding-top: 155px;
  }
}
