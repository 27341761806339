.material-table {

  th {
    white-space: nowrap;
  }
}

.material-table__toolbar-wrap {

  .material-table__toolbar {
    display: flex;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
  }

  .material-table__toolbar-button {
    height: 36px;
    width: 36px;
    padding: 0;
  }
}

.material-table__toolbar-selected {
  margin-right: 10px;
}

.material-table__toolbar-selection {
  display: flex;
  align-items: center;
}

.material-table__toolbar-actions {

  .action-button {
    align-items: center;
    margin-bottom: 0;
  }
}

.material-table__row {
  transition: 0.3s;

  &[role="checkbox"] {
    cursor: pointer;
  }

  &[aria-checked="true"] {

    @include themify($themes) {
      background-color: themed('colorHover');
    }

    .material-table__checkbox span {
      color: $color-accent;
    }
  }

  &:hover {

    @include themify($themes) {
      background-color: themed('colorBackgroundBody') !important;
    }
  }
}

.material-table__checkbox--checked span {
  color: $color-accent;
}

.material-table__cell.material-table__cell--sort {

  span {
    transition: 0.3s;

    &:hover {
      color: $color-accent;
    }
  }
}

.material-table__cell, .material-table__sort-label, .material-table__toolbar-button {

  @include themify($themes) {
    color: themed('colorText');
  }
}

.material-table__checkbox {
  transition: 0.3s;

  @include themify($themes) {
    color: themed('colorIcon');
  }

  &:hover {
    background-color: lighten($color-accent, 40%) !important;
  }
}

.material-table__cell--operations {
  padding: 5px !important;

  .material-table__cell__btn {
    border-radius: 50%;
    margin-bottom: 0;
    margin-right: 0;
    padding: 10px;

    &:before {

      @include themify($themes) {
        background-color: darken(themed('colorBackgroundBody'), 10%);
      }
    }

    svg {
      height: 20px;
      width: 20px;
      margin: 0;

      @include themify($themes) {
        fill: themed('colorText');
      }
    }
  }
}

@media screen and (max-width: 420px) {

  .material-table__toolbar-selected {

    span {
      display: none;
    }
  }
}
