.permission {
  &__section {
    border: 1px solid;
    border-color: #e7e2e2;
    border-radius: 5px;
    padding: 10px;

    .toggle-btn {
      width: auto;
      margin: 0;
      border: none;
    }
  }

  &__section-row {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    &--sub {
      padding-left: 55px;
    }
  }

  &__label {
    color: #646777;
    margin-left: 5px;
  }
}
