.alertbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.alert {
  border-radius: 0;
  position: relative;
  margin-bottom: 10px;
  padding: 0;
  display: flex;
  justify-content: center;

  &.fade {
    opacity: 1;
  }

  &:last-child {
    margin-bottom: 0;
  }

  p {
    color: #ffffff;
    margin-top: 0;
  }

  .close {
    color: white;
    opacity: 1;
    right: 20px;
    position: absolute;
    top: calc(50% - 14px);

    &:focus, &:active, &:active:focus {
      outline: none;
    }
  }

  &.alert-info {
    background-color: $color-blue;
    border-color: $color-blue;
  }

  &.alert-success {
    background-color: $color-green;
    border-color: $color-green;
  }

  &.alert-warning {
    background-color: $color-yellow;
    border-color: $color-yellow;
  }

  &.alert-danger {
    background-color: $color-red;
    border-color: $color-red;
  }
}

.alert__content {
  padding: 10px 40px 10px 20px;
}
